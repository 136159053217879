"use client";
import React from "react";
import { Button as MantineButton } from "@mantine/core";
import { commonTypes } from "./types";

interface ButtonProps extends commonTypes {
  children: React.ReactNode;
  onClick?: () => void;
  loading?: boolean;
  leftSection?: React.ReactSVGElement;
  rightSection?: React.ReactSVGElement;
  ariaLabel: string;
  type?: "submit" | "reset" | undefined;
  fullWidth?: boolean;
  styles?: any;
  className?: string;
  color?: string;
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  loading,
  disabled,
  id,
  leftSection,
  rightSection,
  ariaLabel,
  type,
  fullWidth,
  styles,
  className,
  color,
}) => {
  return (
    <MantineButton
      loading={loading}
      leftSection={leftSection}
      rightSection={rightSection}
      onClick={onClick}
      disabled={disabled}
      id={id}
      aria-label={ariaLabel}
      type={type}
      fullWidth={fullWidth || false}
      styles={styles ? { ...styles } : {}}
      color={color || ""}
      className={className || ""}
    >
      {children}
    </MantineButton>
  );
};

export default Button;
