"use client";
import React from "react";
import { TextInput, TextInputProps } from "@mantine/core";
import "./style.css";

const Input: React.FC<TextInputProps> = ({
  type,
  label,
  value,
  onChange,
  withAsterisk,
  styles,
  error,
  disabled,
  id,
  className,
  placeholder,
}) => {
  return (
    <TextInput
      label={label}
      type={type}
      value={value}
      onChange={onChange}
      withAsterisk={withAsterisk || false}
      styles={styles ? { ...styles } : {}}
      error={error}
      disabled={disabled}
      id={id}
      className={className}
      placeholder={placeholder}
    />
  );
};

export default Input;
