"use client";
import Button from "@/components/Mantine/Button";
import Input from "@/components/Mantine/Input";
import { enqueueSnackbar } from "@/features/auth/authSlice";
import { fetchData, postRequest } from "@/utils/Api";
import { errorBuildLvl1 } from "@/utils/helpers";
import { PasswordInput } from "@mantine/core";
import Joi from "joi";
import { signIn } from "next-auth/react";
import Link from "next/link";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  RiEye2Line,
  RiEyeCloseLine,
  RiFacebookCircleFill,
} from "react-icons/ri";
import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from "@/utils/constans";
import Image from "next/image";
import GoogleIcon from "@/assets/img/google.png";
import { getCookie } from "@/utils/cookies";
import { useFetchSellerDetailsMutation } from "@/features/auth/authApiSlice";

const loginSchEmail = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label("Email"),
  password: Joi.string().required().label("Password"),
});

const loginSchMbl = Joi.object({
  email: Joi.string().min(3).max(30).required().label("Mobile Number"),
  password: Joi.string().required().label("Password"),
});

const verifySchEmail = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label("Email"),
});

const verifySchMbl = Joi.object({
  email: Joi.string().length(10).required().label("Mobile Number"),
});

const otpSchEmail = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label("Email"),
  otp: Joi.string().required().label("OTP Code"),
});

const otpSchMbl = Joi.object({
  email: Joi.string().length(10).required().label("Mobile Number"),
  otp: Joi.string().required().label("OTP Code"),
});

const UserLogin = () => {
  const [sellerData, { data }] = useFetchSellerDetailsMutation();

  const loginMethod = data?.data?.login;

  useEffect(() => {
    sellerData("");
  }, []);

  const [recaptchaToken, setRecaptchaToken] = useState("");
  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  const dispatch = useDispatch();
  const [one, setOne] = useState({
    email: "",
    password: "",
    otp: "",
    terms_and_condition: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loginType, setLoginType] = useState("verify");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange =
    (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setOne({ ...one, [name]: e.target.value });
      if (errors[name] && errors[name] !== "") {
        setErrors({ ...errors, [name]: "" });
      }
      if (
        loginType == "login" &&
        name == "email" &&
        one.email !== e.target.value
      ) {
        setLoginType("verify");
      } else if (
        loginType == "otp" &&
        name == "email" &&
        one.email !== e.target.value
      ) {
        setLoginType("verify");
      }
    };

  const handleCheckboxChange =
    (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setOne({ ...one, [name]: event.target.checked });
    };

  const handleCheckUser = async (token: string) => {
    const schema = inputType === "email" ? verifySchEmail : verifySchMbl;
    const { error }: any = schema.validate(
      {
        email: one.email,
      },
      { abortEarly: false }
    );
    if (error) {
      const errorMsg = errorBuildLvl1(error.details);
      setErrors(errorMsg);
    } else {
      const data =
        inputType === "email"
          ? { email: one.email, reCaptcha: token }
          : { mobile_no: one.email, reCaptcha: token };

      const apiUrl = inputType === "email" ? "user/user/register" : "user/auth";
      setLoading(true);
      await postRequest({
        url: apiUrl,
        body: data,
      })
        .then((res) => {
          if (res?.success && res?.data?.redirect == "login") {
            setLoading(false);
            dispatch(
              enqueueSnackbar({
                message: "Enter password to continue",
                options: {
                  variant: "success",
                },
              })
            );
            setLoginType("login");
          } else {
            setLoading(false);
            if (res?.data?.redirect == "otp") {
              setLoginType("otp");
              localStorage.setItem("newUserId", res?.data?._id);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          dispatch(
            enqueueSnackbar({
              message: "Something went wrong! Please try again later.",
              options: {
                variant: "warning",
              },
            })
          );
        });
    }
  };

  //google login
  const handleLoginGoogle = async () => {
    const res = await signIn("google", { redirect: true });
  };

  const handleSubmitOtp = async (otpToken: string) => {
    const schema = inputType === "email" ? otpSchEmail : otpSchMbl;
    const { error }: any = schema.validate(
      {
        email: one.email,
        otp: one.otp,
      },
      { abortEarly: false }
    );
    if (error) {
      const errorMsg = errorBuildLvl1(error.details);
      setErrors(errorMsg);
    } else {
      if (localStorage.getItem("newUserId")) {
        // const data = {
        //   mobile_no: one.email,
        //   otp: one.otp,
        //   _id: localStorage.getItem("newUserId"),
        //   reCaptcha: otpToken,
        // };

        const data =
          inputType === "email"
            ? {
                email: one.email,
                otp: one.otp,
                _id: localStorage.getItem("newUserId"),
                reCaptcha: otpToken,
              }
            : {
                mobile_no: one.email,
                otp: one.otp,
                _id: localStorage.getItem("newUserId"),
                reCaptcha: otpToken,
              };
        setLoading(true);
        await postRequest({
          url: "user/seller/login/email/verify",
          body: data,
        }).then((res) => {
          if (res?.success) {
            const queryString =
              inputType === "email"
                ? `email=${one.email}&otp=${window.btoa(one.otp)}&id=${window.btoa(data._id || "")}`
                : `mobile_no=${one.email}&otp=${window.btoa(one.otp)}&id=${window.btoa(data._id || "")}`;
            window.location.replace(
              `http://${window.location.hostname}/auth/register/mobile?${queryString}`
            );
            dispatch(
              enqueueSnackbar({
                message: res?.msg || "OTP Verified Successfully.",
                options: {
                  variant: "success",
                },
              })
            );
          } else {
            setLoading(false);
            dispatch(
              enqueueSnackbar({
                message: "Something went wrong! Please try again later.",
                options: {
                  variant: "warning",
                },
              })
            );
          }
        });
      } else {
        dispatch(
          enqueueSnackbar({
            message: "New user id missing.",
            options: {
              variant: "warning",
            },
          })
        );
      }
    }
  };

  const handleLogin = async (passwordToken: string) => {
    const schema = inputType === "email" ? loginSchEmail : loginSchMbl;
    const { error }: any = schema.validate(
      {
        email: one.email,
        password: one.password,
      },
      { abortEarly: false }
    );
    if (error) {
      const errorMsg = errorBuildLvl1(error.details);
      setErrors(errorMsg);
    } else {
      setLoading(true);
      let data = {
        email: one.email,
        password: one.password,
        reCaptcha: passwordToken,
      };
      const res = await signIn("login", {
        ...data,
        redirect: false,
      });
      if (res && !res.error) {
        dispatch(
          enqueueSnackbar({
            message: "Signed in successfully.",
            options: {
              variant: "success",
            },
          })
        );
        window.location.replace("/");
      } else {
        const errstr =
          (res && res.error && res.error.replace("Error:", "")) || "";
        try {
          const err = JSON.parse(errstr);
          if (err) {
            setErrors(err);
          }
        } catch (error) {}
        dispatch(
          enqueueSnackbar({
            message: "Sign In Failure.",
            options: {
              variant: "warning",
            },
          })
        );
      }
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    switch (loginType) {
      case "verify":
        // Execute ReCAPTCHA
        const token = await reCaptchaRef.current?.executeAsync();

        // setRecaptchaToken(token || "")
        // recaptchaRef.current?.executeAsync().then((token) => {
        // Call handleCheckUser with ReCAPTCHA token
        handleCheckUser(token || " ");
        // });
        break;
      case "login":
        const passwordToken = await reCaptchaRef.current?.executeAsync();
        handleLogin(passwordToken || " ");
        break;
      case "otp":
        const otpToken = await reCaptchaRef.current?.executeAsync();
        handleSubmitOtp(otpToken || "");
        break;
    }
  };

  let inputLabel, inputType: any;
  if (loginMethod?.mobile && loginMethod?.email) {
    inputLabel = "Mobile Number/Email";
    inputType = "text"; // 'text' type to allow both inputs
  } else if (loginMethod?.mobile) {
    inputLabel = "Mobile Number";
    inputType = "number";
  } else if (loginMethod?.email) {
    inputLabel = "Email";
    inputType = "email";
  }

  return (
    <div className="container mx-auto py-10 lg:py-20">
      <h1 className="hidden lg:block font-semibold text-lg text-center my-2">
        Sign In/Register
      </h1>
      <div className="max-w-md mx-auto lgLborder border-gray-300 lg:shadow-md p-4 lg:p-10">
        <div className="mb-5 mx-auto grid grid-cols-2 gap-3 space-between justify-center">
          {loginMethod?.facebook && (
            <button className={socialBtnsClass}>
              <RiFacebookCircleFill
                style={{ color: "#316FF6" }}
                className="text-2xl"
              />
              <span>Facebook</span>
            </button>
          )}
          {loginMethod?.google && (
            <button onClick={handleLoginGoogle} className={socialBtnsClass}>
              <Image width={20} height={20} src={GoogleIcon} alt="google" />
              <span>Google</span>
            </button>
          )}
        </div>
        {(loginMethod?.google || loginMethod?.facebook) && (
          <div className="text-center uppercase my-4 text-xs">
            or login with
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <ReCAPTCHA
            sitekey={RECAPTCHA_SITE_KEY}
            // onChange={(token) => setRecaptchaToken(token || "")}
            ref={reCaptchaRef}
            size="invisible"
          />
          <Input
            label={inputLabel}
            // label="Mobile Number"
            withAsterisk
            error={errors?.email ?? ""}
            type={inputType}
            // type="number"
            value={one.email ?? ""}
            onChange={handleChange("email")}
            id="mobile_number"
            className="mb-2"
            disabled={loading}
          />
          {loginType == "login" && (
            <>
              <PasswordInput
                withAsterisk
                label="Password"
                value={one.password ?? ""}
                onChange={handleChange("password")}
                className={`w-full`}
                visibilityToggleIcon={({ reveal }) =>
                  reveal ? (
                    <RiEye2Line size={20} />
                  ) : (
                    <RiEyeCloseLine size={20} />
                  )
                }
                disabled={loading}
                error={errors?.password ?? ""}
                autoComplete="on"
                name="password"
              />
              <div className="flex justify-end mt-1 mb-3">
                <Link
                  href={`/auth/forget-password?mobile_no=${one.email}`}
                  prefetch={false}
                  className="text-primary hover:underline text-xs"
                >
                  Forget password?
                </Link>
              </div>
            </>
          )}
          {loginType == "otp" && (
            <Input
              label="OTP Code"
              withAsterisk
              error={errors?.otp ?? ""}
              type="number"
              onChange={handleChange("otp")}
              value={one.otp ?? ""}
              disabled={loading}
              id="mobile_number"
              className="mb-2"
            />
          )}
          {/* <Checkbox
            label={
              <>
                I have read and accept{" "}
                <Anchor
                  href="/page/terms-and-conditions"
                  target="_blank"
                  inherit
                >
                  terms and conditions
                </Anchor>
              </>
            }
            id="terms_conditions"
            name="terms_conditions1"
            className="my-3"
            size="md"
            onChange={handleCheckboxChange("terms_and_condition")}
          /> */}
          <Button
            type="submit"
            loading={loading}
            disabled={one.email !== "" ? false : true}
            id="sign-in"
            ariaLabel="log in button"
            className="w-full"
            fullWidth
            styles={{
              root: {
                backgroundColor: "#ED1C24",
                borderRadius: 0,
                height: 48,
              },
            }}
          >
            {loginType == "verify"
              ? "Continue"
              : loginType == "login"
                ? "Sign In"
                : loginType == "otp"
                  ? "Continue"
                  : ""}
          </Button>
          {/* <div className="text-xs mt-2">
            By continuing, you agree to our{" "}
            <Link
              className="text-primary"
              href="/page/terms-and-conditions"
              prefetch={false}
              target="_blank"
            >
              Terms & Conditions
            </Link>
            .
          </div> */}
        </form>
      </div>
    </div>
  );
};

export default UserLogin;

const socialBtnsClass =
  "border border-gray-300 bg-white rounded flex gap-2 items-center justify-center py-2 hover:bg-gray-100 duration-100";
